import { createContext, useContext } from 'react';

interface CardContextProps {
  size: 'sm' | 'lg';
  isReadOnly: boolean;
}

// Step 2: Create the context with default values
export const CardContext = createContext<CardContextProps>({
  size: 'sm',
  isReadOnly: false,
});

export const useCardContext = () => {
  const context = useContext(CardContext);
  if (!context) {
    throw new Error('useCardContext must be used within a Card component');
  }
  return context;
};
