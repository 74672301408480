import { createMultiStyleConfigHelpers, theme } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  'container',
  'header',
  'body',
]);

// define custom styles for funky variant
const variants = {
  'table-styles': definePartsStyle({
    container: {
      ...theme.components.Card.variants?.outline.container,
      rounded: 'lg',
    },
    header: {
      px: 6,
      py: 5,
      display: 'flex',
      flexDirection: ['column', 'column', 'row'],
      alignItems: ['flex-start', 'flex-start', 'center'],
      justifyContent: 'space-between',
      gap: [4, 2],
      w: 'full',
    },
    body: {
      p: 0,
      borderTop: '1px',
      borderColor: 'inherit',
      overflowX: 'auto',
    },
  }),
};

export const CardTheme = defineMultiStyleConfig({ variants });
