import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonProps,
  useDisclosure,
} from '@chakra-ui/react';
import { isAbortError, useStableCallback } from '@main/shared/utils';
import { createContext, MouseEvent, ReactNode, useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface AlertDialogConfig<T = unknown> {
  dialogHeader: string;
  dialogContent: ReactNode;
  cancelBtnLabel?: string;
  cancelAction?: ButtonProps;
  confirmAction?: Omit<ButtonProps, 'onClick'> & {
    onClick?: (event: MouseEvent<HTMLButtonElement>) => Promise<T> | T;
  };
}

export type AlertDialogContextType = {
  openDialog<T = unknown>(state: AlertDialogConfig<T>): Promise<T>;
  closeDialog(): void;
};

export const defaultAlertDialogContext: AlertDialogContextType = {
  openDialog<T = unknown>() {
    return Promise.resolve<T>(undefined as T);
  },
  closeDialog: () => null,
};

interface AlertDialogState extends AlertDialogConfig {
  isLoading: boolean;
}

const defaultState: AlertDialogState = {
  isLoading: false,
  dialogHeader: '',
  dialogContent: '',
};

const AlertDialogContext = createContext<AlertDialogContextType>(defaultAlertDialogContext);

export const AlertDialogContainer = ({ children }: { children?: ReactNode }) => {
  const { t } = useTranslation('ui');
  const resolversRef = useRef(createPromiseResolvers());
  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => resolversRef.current.reject(new Error('AbortError')),
  });
  //chakra-ui.com/docs/components/alert-dialog/usage#usage
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [state, setState] = useState<AlertDialogState>(defaultState);

  const openDialog = useStableCallback((newState: AlertDialogConfig) => {
    resolversRef.current = createPromiseResolvers();
    setState({ ...defaultState, ...newState });
    onOpen();
    return resolversRef.current.promise;
  }) as <T>(state: AlertDialogConfig<T>) => Promise<T>;

  function handleCancel(event: MouseEvent<HTMLButtonElement>) {
    state.cancelAction?.onClick?.(event);
    onClose();
  }

  async function handleConfirm(event: MouseEvent<HTMLButtonElement>) {
    try {
      setState((prev) => ({ ...prev, isLoading: true }));
      const result = await state.confirmAction?.onClick?.(event);
      resolversRef.current.resolve(result);
      onClose();
    } finally {
      setState((prev) => ({ ...prev, isLoading: false }));
    }
  }

  const contextValue = useMemo(() => ({ openDialog, closeDialog: onClose }), [openDialog, onClose]);

  return (
    <AlertDialogContext.Provider value={contextValue}>
      {children}
      <AlertDialog
        size="xl"
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered={true}
        motionPreset="slideInBottom"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg">{state.dialogHeader}</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody whiteSpace="pre-line">{state.dialogContent}</AlertDialogBody>
            <AlertDialogFooter gap={3}>
              <Button
                colorScheme="gray"
                ref={cancelRef}
                {...state.cancelAction}
                onClick={handleCancel}
              >
                {state.cancelBtnLabel || state.cancelAction?.children || t('alert.cancel')}
              </Button>
              <Button
                colorScheme="red"
                {...state.confirmAction}
                onClick={handleConfirm}
                isLoading={state.isLoading}
              >
                {state.confirmAction?.children || t('alert.confirm')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </AlertDialogContext.Provider>
  );
};

export const useAlertDialog = () => useContext(AlertDialogContext);

/**
 * Create promise resolvers with suppressed `AbortError` rejections
 * to avoid unhandled promise rejection warnings
 */
function createPromiseResolvers() {
  const resolvers = Promise.withResolvers();
  // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
  resolvers.promise.catch((e) => (isAbortError(e) ? undefined : Promise.reject(e)));

  return resolvers;
}
