import {
  As,
  Box,
  BoxProps,
  Flex,
  FormControl,
  FormControlProps,
  FormLabel,
  FormLabelProps,
  Icon,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { ReactNode, useMemo, useRef, useState } from 'react';

import { getStringWidth } from '../../utils';
import { PROPERTY_DEFAULT_HEIGHT } from './constants';

interface DrawerPropertyProps extends FormControlProps {
  children: ReactNode;
}
export function DrawerProperty({ children, ...formProps }: DrawerPropertyProps) {
  return (
    <FormControl
      display="flex"
      gap={2}
      minH={PROPERTY_DEFAULT_HEIGHT}
      flexDirection={['column', 'column', 'row']}
      {...formProps}
    >
      {children}
    </FormControl>
  );
}

interface LabelProps extends FormLabelProps {
  children: string;
  icon?: As;
  tooltip?: string;
}
const LABEL_WIDTH = 135;
const LABEL_TEXT_WIDTH = 135 - 16 - 8; // 16px icon, 8px padding
const Label = ({ children, icon, tooltip, ...labelProps }: LabelProps) => {
  return (
    // reset flex for when direction is column set in parent component
    <Flex flex={['', '', `0 0 ${LABEL_WIDTH}px`]} gap={2} color="gray.500" py={2.5}>
      {icon && <Icon as={icon} boxSize={4} />}
      <TruncatedLabel {...labelProps}>{children}</TruncatedLabel>
      {tooltip && (
        <Tooltip
          label={tooltip}
          hasArrow
          placement="right"
          openDelay={300}
          fontSize="sm"
          rounded={4}
        >
          <Icon as={InformationCircleIcon} boxSize={4} ml={-1} />
        </Tooltip>
      )}
    </Flex>
  );
};

interface TrancatedLabelProps extends FormLabelProps {
  children: string;
}
const TruncatedLabel = ({ children, ...props }: TrancatedLabelProps) => {
  const labelRef = useRef<HTMLLabelElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  const updatedLabel = useMemo(
    () =>
      children
        .split(' ')
        .map((word) => {
          const width = getStringWidth(word, labelRef.current);

          if (width > LABEL_TEXT_WIDTH) {
            if (!isTruncated) {
              setIsTruncated(true);
            }
            return `${word.substring(0, 4)}...`;
          }
          return word;
        })
        .join(' '),
    [children, isTruncated],
  );

  return (
    <FormLabel ref={labelRef} {...props} m={0} fontWeight="medium" lineHeight={4}>
      <Tooltip
        label={children}
        hasArrow
        placement="right"
        openDelay={500}
        fontSize="sm"
        rounded={4}
        isDisabled={!isTruncated}
      >
        {updatedLabel}
      </Tooltip>
    </FormLabel>
  );
};

interface ContentProps extends BoxProps {
  children: ReactNode;
}
const Content = ({ children, ...boxProps }: ContentProps) => {
  const contentColor = useColorModeValue('gray.600', 'gray.500');
  return (
    <Box flexGrow={1} color={contentColor} {...boxProps}>
      {children}
    </Box>
  );
};

DrawerProperty.Label = Label;
DrawerProperty.Content = Content;
