/**
 * To get the width of a string in pixels, we need to create a canvas element and measure the text width.
 * This function takes a string, and container element to get font styles, and returns the width of the string in pixels.
 *
 * The following link provides a detailed explanation of the function:
 * https://stackoverflow.com/a/21015393/7007794
 */

let canvas: HTMLCanvasElement | undefined;

/* Default font used for our Drawer property labels */
const defaultChakraFont =
  '500 12px Inter, -apple-system, "system-ui", "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol';

export function getStringWidth(word: string, containerEl: HTMLElement | null) {
  if (!canvas) {
    canvas = document.createElement('canvas');
  }

  const context = canvas.getContext('2d');

  if (!context) {
    return 0;
  }

  context.font = containerEl ? getContainerElementFont(containerEl) : defaultChakraFont;
  return context.measureText(word).width;
}

function getContainerElementFont(el: HTMLElement) {
  const fontWeight = getCssStyle(el, 'font-weight');
  const fontSize = getCssStyle(el, 'font-size');
  const fontFamily = getCssStyle(el, 'font-family');

  return `${fontWeight} ${fontSize} ${fontFamily}`;
}

function getCssStyle(element: HTMLElement, prop: string) {
  return window.getComputedStyle(element, null).getPropertyValue(prop);
}
