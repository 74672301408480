import { useMultipleFilesUpload } from '@nhost/react';
import { useMemo } from 'react';

import { FileInputContext } from './file-input';
import {
  FileUploadResult,
  getFileUploadResult,
  getMultipleFilesUploadResult,
  MultipleFilesUploadResult,
  UploadedFile,
} from './shared';

export function useEagerFileUpload({
  file,
  onFileUpload,
  onFileDelete,
  onFileDownload,
}: {
  file?: UploadedFile;
  onFileUpload?: (result: FileUploadResult) => Promise<void>;
  onFileDelete?: () => Promise<void>;
  onFileDownload?: (fileId: string) => void;
}) {
  const fileUpload = useEagerMultipleFilesUpload({
    files: file ? [file] : undefined,
    onFilesUpload: onFileUpload
      ? async (uploadResult) => {
          await onFileUpload(getFileUploadResult(uploadResult));
        }
      : undefined,
    onFileDelete,
    onFileDownload,
  });

  return {
    ...fileUpload,
    isMulti: false,
  };
}

export function useEagerMultipleFilesUpload({
  files,
  onFilesUpload,
  onFileDelete,
  onFileDownload,
}: {
  files?: UploadedFile[];
  onFilesUpload?: (result: MultipleFilesUploadResult) => Promise<void>;
  onFileDelete?: (fileId: string) => Promise<void>;
  onFileDownload?: (fileId: string) => void;
}): FileInputContext {
  const filesUpload = useMultipleFilesUpload();
  const stagedFiles = useMemo(
    () =>
      files
        ? filesUpload.files.filter((stagedFile) => !files.some((file) => file.id === stagedFile.id))
        : filesUpload.files,
    [filesUpload.files, files],
  );

  return {
    isMulti: true,
    uploadedFiles: files,
    onUploadedFileDownload: onFileDownload,
    onUploadedFileDelete: async (file) => {
      await onFileDelete?.(file.id);
    },
    stagedFiles,
    onStagedFileDelete: (fileId) => {
      filesUpload.files.find((file) => file.id === fileId)?.send('DESTROY');
    },
    onStagedFilesAdd: onFilesUpload
      ? async (files) => {
          const uploadResult = await filesUpload.upload({ files });
          await onFilesUpload(getMultipleFilesUploadResult(uploadResult));
          filesUpload.clear();
        }
      : undefined,
  };
}
