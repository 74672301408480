import { createColumnHelper as _createColumnHelper } from '@tanstack/react-table';

import * as columns from './columns';
import { HasuraFilter, useServerPagination } from './filters';

export function createColumnHelper<
  TData,
  TFilter extends HasuraFilter = never,
  TSort extends object = never,
>() {
  return {
    ..._createColumnHelper<TData>(),
    useServerPagination: useServerPagination<TData, TFilter, TSort>,
    columns: {
      actions: columns.actions<TData>(),
      avatar: columns.avatar<TData, TFilter, TSort>(),
      status: columns.status<TData, TFilter, TSort>(),
      tag: columns.tag<TData, TFilter, TSort>(),
      text: columns.text<TData, TFilter, TSort>(),
      date: columns.date<TData, TFilter, TSort>(),
      number: columns.number<TData, TFilter, TSort>(),
    },
  };
}
